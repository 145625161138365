
import RealEstateDataSourcesListCard from '@/components/real-estate/RealEstateDataSourcesListCard.vue';
import RealEstateListingsListCard from '@/components/real-estate/RealEstateListingsListCard.vue';
import { ProjectViewMixin } from '@/mixins/ProjectViewMixin';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';

@Component({
  name: 'project-content-real-estate',
  components: { RealEstateListingsListCard, RealEstateDataSourcesListCard },
})
export default class ProjectContentRealEstate extends mixins(ProjectViewMixin) {}
