
import { REAL_ESTATE_DATA_SOURCES_METADATA } from '@/constants';
import { BotMixin } from '@/mixins/BotMixin';
import { ServiceMetadata } from '@/types';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';

@Component({
  name: 'real-estate-data-sources-list-card',
})
export default class RealEstateDataSourcesListCard extends mixins(BotMixin) {
  get dataSourcesMetadata(): ServiceMetadata[] {
    return REAL_ESTATE_DATA_SOURCES_METADATA;
  }
}
